import request from '@/utils/request'


//获取省
export function getProvince() {
  return request({
    url: '/city/getCityAll',
    method: 'get'
  })
}


//=========================================================
// 因为新增了专家数据模块
